<template>
    <div>
      <Header/>
      <div class="content_text_s">
        <!-- <router-link :to="{path:'../'}">
          <img class="content_img_1_s" src="../../assets/content/logo_s.png">
        </router-link> -->
        <img class="content_img_2_s" src="../../assets/my/time_icon.png">
        <div calss="content_study_s">活動記錄</div>
      </div>
      <div>
        <div style="width: 100%; height: 204px;">
          <img :src="image" v-if="image" style="width:100%;height:100%;">
        </div>
        <div>
          <div class="lesson-title">{{title}}</div>
          <div class="lesson-content">{{content}}</div>
        </div>
      </div>
    </div>
</template>

<script>
import Header from "../Header";
import {apiUserClockLogsDetail} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
      // list:[
      //   {
      //     img:require("@/assets/my/detail_1.jpg"),
      //     title:'《1》',
      //     text:'11111111111111111111111111111111111111111111111111',
      //   },
      //   {
      //     img:require("@/assets/my/detail_2.jpg"),
      //     title:'《2》',
      //     text:'2222222222222222222222222222222222222222222222',
      //   },
      //   {
      //     img:require("@/assets/my/detail_3.jpg"),
      //     title:'《3》',
      //     text:'333333333333333333333333333333333333333333333333333333',
      //   },
      //   {
      //    img:require("@/assets/my/detail_1.jpg"),
      //   title:'《4》',
      //   text:'4444444444444444444444444444444444444444444444444444444444444',
      //   },
      // ],
      // content:[],
    title:'',
    content:'',
    }
  },
  created(){
    // this.content = this.list[this.$route.params.id - 1];
    let id = this.$route.params.id;
    if(id && id>0){
        apiUserClockLogsDetail(id,{
          lang_id:this.$store.state.lang_id
        }).then(res=>{
          if(res.data){
            this.title = res.data.read_title;
            this.content = res.data.read_content;
            this.image = res.data.read_photo;
          }
        }).catch(error=>{
          console.log(error);
        });
    }
  }
}
</script>
<style>
div{
  word-break: break-all;
}
.lesson-title{
  padding: 0 15px 15px 15px;
  padding-top: 15px;
  font-family: Helvetica Neue;
  font-weight: 600;
  font-size: 25px;
  text-align: left;
  color:#fff;
}
.lesson-content{
  padding: 0 15px 15px 15px;
  font-family: Helvetica Neue;
  font-weight: 300;
  font-size: 16px;
  text-align: left;
  color:#fff;
}
p{
  margin:0;
}
.p2{
  margin-top: 3px;
  font-family: Helvetica Neue;
  font-size: 14px;
  text-align: left;
  color: #fff;
  opacity: .7;
}
.p1{
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 17px;
  text-align: left;
  color: #fff;
}
.listitem-item-text-con{
  margin-left: 15px;
  margin-right: 15px;
}
.listitem-item{
  height: 72px;
  border-bottom: 1px solid #fff;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: initial;
}
.content_study_s{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
 .content_img_1_s{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_img_2_s{
    width: 50px;
    height: 42px;
    margin-right: 8px;
  }
.content_text_s{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff;
  }
</style>